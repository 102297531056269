var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box_1"},[_c('div',{staticClass:"top"},[(!_vm.totalShow && !_vm.realTimeShow && !_vm.isCategory)?_c('div',{staticClass:"menu_title"},[_vm._v(" "+_vm._s(_vm.item.menu_title)+" ")]):_vm._e(),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.item.thumbnail,"alt":""},on:{"error":_vm.replaceImg,"click":function($event){return _vm.openDetail()}}}),_c('span',[_vm._v(_vm._s(_vm.index))])]),_c('div',{staticClass:"txt"},[_c('h3',{on:{"click":function($event){return _vm.openDetail()}}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.item.option1)+_vm._s(_vm.item.option2 ? ("> " + (_vm.item.option2)) : ""))])])]),_c('ul',{staticClass:"bottom"},[_c('li',[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.likeCheck({
              wish_yn: _vm.item.wish_yn_reviwer,
              reviewer_seq: _vm.item.member_seq,
              review_seq: null,
              main_seq: _vm.item.main_menu_seq,
              type: 'reviewer',
            })}}},[(_vm.item.wish_yn_reviwer == 'Y')?_c('img',{attrs:{"src":require("@/assets/images/sub/like_on.png"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/images/sub/like_off.png"),"alt":""}})]),_c('router-link',{attrs:{"to":""}},[_vm._v(_vm._s(_vm.item.nickname))])],1),_c('li',[_vm._v("리뷰어 신뢰도 "+_vm._s(_vm.rammFloat(_vm.item.re_trust_score)))]),_c('li',[_vm._v("중요도 "+_vm._s(_vm.rammFloat(_vm.item.news_total_score)))]),_c('li',[_c('router-link',{staticClass:"detail_arrow",attrs:{"to":"","id":("arrow_" + (_vm.item.seq))},nativeOn:{"click":function($event){return _vm.openDetail()}}},[_vm._v("∨")])],1)]),(!_vm.totalShow && !_vm.realTimeShow && !_vm.isCategory)?_c('router-link',{staticClass:"move_ico",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.goDetailList.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/sub/list_move_ico.jpg"),"alt":""}})]):_vm._e()],1),_c('div',{staticClass:"box_2 detail",staticStyle:{"display":"none"},attrs:{"id":("item_detail_" + (_vm.item.seq))}},[_c('div',{staticClass:"top_2"},[(
          _vm.myInfo && parseInt(_vm.myInfo.member_seq) === parseInt(_vm.item.member_seq)
        )?_c('div',{staticClass:"left"},[(parseInt(_vm.item.member_seq) === parseInt(_vm.isReviewer))?[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.modifyReview.apply(null, arguments)}}},[_vm._v("수정")]),_vm._v("  |  "),_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.deleteReview.apply(null, arguments)}}},[_vm._v("삭제")])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"right"},[_c('b',[_vm._v("리뷰어와 독자들의 뉴스 중요도 합산 평점  "+_vm._s(_vm.rammFloat(_vm.item.news_total_score)))])])]),_c('h3',[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('div',{staticClass:"middle",domProps:{"innerHTML":_vm._s(_vm.item.content)}},[_vm._v(" "+_vm._s(_vm.item.content)+" ")]),_c('div',{staticClass:"middle_end"},[_c('div',{staticClass:"left"},[_vm._v(_vm._s(_vm.item.nickname))]),_c('div',{staticClass:"right"},[_vm._v(_vm._s(_vm.day(_vm.item.reg_dt)))])]),_c('hr',{staticClass:"content_line"}),_c('br'),(_vm.item.news_link)?_c('div',{staticClass:"sect_news_link"},[_c('router-link',{attrs:{"to":""},nativeOn:{"click":function($event){return _vm.linkClick(_vm.item.news_link)}}},[_c('div',{staticClass:"link_move"},[_c('div',{staticClass:"left"},[_vm._v("원문기사보기")]),_c('div',{staticClass:"right"},[_vm._v(">>>")])]),(_vm.item.meta)?_c('div',{staticClass:"link_bottom"},[_c('div',{staticClass:"link_b_left"},[_c('img',{attrs:{"src":_vm.item.meta.og_image,"alt":""},on:{"error":_vm.replaceImg}})]),_c('div',{staticClass:"link_b_right"},[_c('div',{staticClass:"news_title",domProps:{"innerHTML":_vm._s(_vm.item.meta.og_title)}},[_vm._v(" "+_vm._s(_vm.item.meta.og_title)+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.meta.og_description)}},[_vm._v(" "+_vm._s(_vm.item.meta.og_description)+" ")])])]):_vm._e()])],1):_vm._e(),(
        parseInt(_vm.item.option_category1) !== this.realTime &&
        parseInt(_vm.item.option_category1) !== this.media
      )?_c('div',{staticClass:"info_bt",on:{"click":function($event){return _vm.openInfo()}}},[_vm._v(" * '기사읽기'를 하신 분은 아래 평점을 매겨주세요!"),_c('router-link',{attrs:{"to":""}},[_c('span',{attrs:{"id":("arrow_info_" + (_vm.item.seq))}},[_vm._v("∨")])])],1):_vm._e(),_c('div',{staticClass:"info_word",attrs:{"id":("arrow_info_word_" + (_vm.item.seq))}},[_c('p',[_vm._v(" 리뷰의 원 기사를 읽은 후, 아래 '리뷰 만족도'와 '뉴스 중요도'에 평점을 매겨주세요. 여러분의 활동에 의해 이 리뷰와 리뷰어의 신뢰도가 결정됩니다. ")]),_c('p',[_vm._v(" 리뷰어의 리뷰 중 과장이나 축소, 왜곡 등등은 모두 부정직한 리뷰입니다 리뷰의 본질을 망각한 글은 누구나 '신고'할 수 있으며 일정 수 이상에 의해 신고된 리뷰는 자동 검색 누락이 됩니다. 악의적인 신고로 피해를 당한 리뷰어는 당사에 항의할 수 있으며 피해자로 확인될 경우 그 리뷰는 복원됩니다 ")])]),(
        parseInt(this.item.option_category1) === parseInt(this.realTime) ||
        parseInt(this.item.option_category1) === parseInt(this.media)
      )?_c('MobAd320X250inner'):_vm._e(),(
        parseInt(this.item.option_category1) === parseInt(this.realTime) ||
        parseInt(this.item.option_category1) === parseInt(this.media)
      )?_c('div',{staticClass:"info_bt"},[_vm._v(" * '리뷰' 평점을 매겨주세요. ")]):_vm._e(),_c('ReviewSetScore',{ref:"review_score",attrs:{"id":3,"item":_vm.item,"myInfo":_vm.myInfo},on:{"updateScores":_vm.updateScores,"needsLogin":_vm.needsLogin}}),_c('div',{staticClass:"rn_ms_empty_20"}),_c('div',{staticClass:"btn_2"},[_c('router-link',{staticClass:"left black",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.likeCheck({
            wish_yn: _vm.item.wish_yn,
            review_seq: _vm.item.seq,
            reviewer_seq: null,
            main_seq: _vm.item.main_menu_seq,
            type: 'review',
          })}}},[_vm._v("♡ 이 리뷰 찜하기")]),_c('router-link',{staticClass:"right white",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.openDeclaration()}}},[_vm._v("신고하기")]),_c('router-link',{staticClass:"right gray",attrs:{"to":""},nativeOn:{"click":function($event){return _vm.openPopSns()}}},[_vm._v("SNS공유하기")]),_c('ReviewSnsShare',{ref:("sns_share_" + (_vm.item.seq)),attrs:{"item":_vm.item,"nowPage":_vm.pageUrl,"id":("sns_share_" + (_vm.item.seq))}}),_c('ReviewDeclaration',{staticStyle:{"display":"none"},attrs:{"item":_vm.item,"id":("declaration_" + (_vm.item.seq))}})],1),_c('div',{staticClass:"reply_btn",on:{"click":function($event){return _vm.openReply()}}},[_c('router-link',{attrs:{"to":""}},[_vm._v("댓글 "+_vm._s(_vm.item.comment_cnt)+"개 "),_c('span',{attrs:{"id":("arrow_reply_" + (_vm.item.seq))}},[_vm._v("∨")])])],1),_c('div',{staticClass:"rn_ms_empty_20"}),_c('ReviewReply',{ref:("reply_view_" + (_vm.item.seq)),staticClass:"reply_list_box",staticStyle:{"display":"none"},attrs:{"id":("reply_view_" + (_vm.item.seq)),"item":_vm.item,"replyListArray":_vm.replyListArray,"page":parseInt(_vm.replyPage)},on:{"setSort":_vm.setSort,"getReplyList":_vm.getReplyList}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }