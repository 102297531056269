<template>
  <div class="score_wrap">
    <div
      :id="`top_tab_${item.seq}`"
      class="top_tab"
      v-if="parseInt(item.option_category1) == 2"
    >
      <div class="on" :style="compareGoods(item).length < 2 ? 'width:50%' : ''">
        <router-link
          to=""
          @click.native="showScore(item.main_score, item.goods_seq, $event)"
          >A({{ item.main_goods_name }})</router-link
        >
      </div>
      <div
        class=""
        v-for="(compare, index) in compareGoods(item)"
        :key="index"
        :style="compareGoods(item).length < 2 ? 'width:50%' : ''"
      >
        <router-link
          to=""
          @click.native="showScore(compare.score, compare.seq, $event)"
          >{{ index < 1 ? "B" : "C" }}({{ compare.goods_name }})</router-link
        >
      </div>
    </div>
    <div
      class="top"
      :style="parseInt(item.option_category1) !== 2 ? 'padding-top:10px' : ''"
    >
      <div class="left" :style="parseInt(id) == 1 ? 'width:auto' : ''">
        <p>{{ titles.scoreTitle }} {{ mainScore }}</p>
        <h6>{{ titles.reviewScoreTitle }}</h6>
      </div>
      <div
        class="right"
        :style="parseInt(id) == 1 ? 'width:calc(100% - 160px)' : ''"
      >
        <a
          href="javascript:void(0)"
          @click="
            parseInt(defaultScore) > 1 ? (defaultScore -= 1) : defaultScore
          "
          >▼</a
        ><input
          type="text"
          name=""
          :value="item.score ? item.score : defaultScore"
          readonly
        /><a
          href="javascript:void(0)"
          @click="
            parseInt(defaultScore) < 5 ? (defaultScore += 1) : defaultScore
          "
          >▲</a
        >
      </div>
    </div>
    <div class="bottom">
      <div class="left">리뷰만족도</div>
      <div class="right">
        <div :class="{ on: parseInt(item.review_score) === 1 }">
          <router-link to="" @click.native="setScore(1, '불만')"
            >불만</router-link
          >
        </div>
        <div :class="{ on: parseInt(item.review_score) === 2 }">
          <router-link to="" @click.native="setScore(2, '미흡')"
            >미흡</router-link
          >
        </div>
        <div :class="{ on: parseInt(item.review_score) === 3 }">
          <router-link to="" @click.native="setScore(3, '보통')"
            >보통</router-link
          >
        </div>
        <div :class="{ on: parseInt(item.review_score) === 4 }">
          <router-link to="" @click.native="setScore(4, '만족')"
            >만족</router-link
          >
        </div>
        <div :class="{ on: parseInt(item.review_score) === 5 }">
          <router-link to="" @click.native="setScore(5, '좋음')"
            >좋음</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      defaultScore: 3,
      score: this.item.main_score,
      goodSeq: this.item.goods_seq,
      scoreTitle: "리뷰어의 제품 평점",
      reviewScoreTitle: "내가 생각하는 제품 평점은",
      orgScore: this.item.score,
    };
  },
  props: {
    id: {
      type: Number,
      default: 1,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapState("review", ["result", "msg"]),
    mainScore: {
      set(score) {
        this.score = score;
      },
      get() {
        return this.score;
      },
    },
    buyGoodsSeq: {
      set(seq) {
        this.goodSeq = seq;
      },
      get() {
        return this.goodSeq;
      },
    },
    titles() {
      if (parseInt(this.id) === 1) {
        return {
          scoreTitle: "리뷰어의 제품 평점",
          reviewScoreTitle: "내가 생각하는 제품 평점은",
        };
      } else if (parseInt(this.id) === 3) {
        return {
          scoreTitle: "리뷰어의 뉴스 중요도",
          reviewScoreTitle: "내가 생각하는 뉴스 중요도는",
        };
      } else {
        return {
          scoreTitle: "리뷰어의 제품 평정",
          reviewScoreTitle: "내가 생각하는 제품 평점은",
        };
      }
    },
  },
  methods: {
    showScore(score, seq, event) {
      [...document.querySelector(`#top_tab_${this.item.seq}`).childNodes].map(
        (x) => x.classList.remove("on")
      );
      event.target.closest("div").classList.add("on");
      if (this.item.main_goods_seq === seq) {
        this.item.score = this.orgScore;
      } else {
        this.item.score = 3;
      }
      this.defaultScore = 3;
      this.mainScore = score;
      this.buyGoodsSeq = seq;
    },
    clearTopTab() {
      [...document.querySelector(`#top_tab_${this.item.seq}`).childNodes].map(
        (x) => x.classList.remove("on")
      );
      [
        ...document.querySelector(`#top_tab_${this.item.seq}`).childNodes,
      ][0].classList.add("on");
      this.mainScore = this.item.main_score;
    },
    compareGoods(item) {
      let data = [];
      let goods_name = item.compare_goods_name.split("|");
      let score = item.compare_score.split(",");
      let seq = item.compare_goods_seq.split(",");

      goods_name.forEach((name, index) => {
        data.push({
          goods_name: name,
          score: score[index],
          seq: seq[index],
        });
      });
      return data;
    },
    setScore(score, gubun) {
      if (!this.$store.state.member.isLogin) {
        this.$emit("needsLogin");
        return false;
      }
      let msg = null;
      if (parseInt(this.id) === 1) {
        msg = `제품 평점 '${this.defaultScore}점' 과 리뷰 만족도 '${gubun}' 으로 리뷰를 평가 하시겠습니까?`;
        const goodsSeq = this.buyGoodsSeq;
        if (this.item.score !== null) {
          if (parseInt(this.item.main_goods_seq) !== parseInt(goodsSeq)) {
            this.$toast.default("평점을 부여할 수 없는 상품 입니다.");
            return false;
          }

          if (this.item.score && this.item.review_score) {
            this.$toast.default("이미 평가한 리뷰 입니다.", { duration: 3000 });
            return false;
          }

          if (parseInt(this.item.score) > 0) {
            this.$toast.default("이미 제품 평점을 부여하셨습니다.");
            return false;
          } else {
            this.$confirm(msg)
              .then(async () => {
                await this.$store.dispatch("review/setMakeReviewGoodsScore", {
                  review_score: score,
                  score: this.defaultScore,
                  goods_seq: this.item.main_goods_seq,
                  review_seq: this.item.seq,
                  order_seq: this.item.order_seq,
                });
                if (this.result) {
                  this.$emit("updateScores", score, this.defaultScore);
                }
                this.$toast.default(this.msg);
              })
              .catch(() => {
                return false;
              });
          }
        } else {
          this.$toast.default("해당 리뷰를 통해 최근 구매한 이력이 없습니다.");
          return false;
        }
      }
      if (parseInt(this.id) === 3) {
        msg = `뉴스 중요도 '${this.defaultScore}점' 과 리뷰 만족도 '${gubun}' 으로 리뷰를 평가 하시겠습니까?`;
        if (
          parseInt(this.myInfo.member_seq) === parseInt(this.item.member_seq) &&
          this.$store.state.mypage.channel.reviewer
        ) {
          this.$toast.default(
            "본인이 작성한 리뷰에는 평점을 부여하실 수 없습니다."
          );
          return false;
        }
        if (this.item.score && this.item.review_score) {
          this.$toast.default("이미 평가한 리뷰 입니다.", { duration: 3000 });
          return false;
        }
        if (
          parseInt(this.item.option_category1) !== parseInt(this.realTime) &&
          parseInt(this.item.option_category1) !== parseInt(this.media)
        ) {
          let news = localStorage.getItem("news");
          if (!news) {
            this.$toast.default(
              "뉴스 평점을 부여하기 위해 '기사읽기' 해주세요."
            );
            return false;
          }
          news = news.split(",");
          let index = news.findIndex(
            (n) => parseInt(n) === parseInt(this.item.seq)
          );
          if (index === -1) {
            this.$toast.default(
              "뉴스 평점을 부여하기 위해 '기사읽기' 해주세요."
            );
            return false;
          }
          this.$confirm(msg)
            .then(async () => {
              await this.$store.dispatch("review/setMakeReviewNewsScore", {
                review_score: score,
                score: this.defaultScore,
                review_seq: this.item.seq,
                option1: this.item.option_category1,
              });
              if (this.result) {
                this.$emit("updateScores", score, this.defaultScore);
              }
              this.$toast.default(this.msg);
            })
            .catch(() => {
              return false;
            });
        }
      }
    },
  },
};
</script>

<style></style>
