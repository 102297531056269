<template>
  <div class="box">
    <div class="box_1">
      <div class="top">
        <div
          class="menu_title"
          v-if="!totalShow && !realTimeShow && !isCategory"
        >
          {{ item.menu_title }}
        </div>
        <div class="img">
          <img
            :src="item.thumbnail"
            @error="replaceImg"
            alt=""
            @click="openDetail()"
          />
          <span>{{ index }}</span>
        </div>
        <div class="txt">
          <h3 @click="openDetail()">
            {{ item.title }}
            <!-- {{ item.title }} {{ item.news_total_score }} /
            {{ item.re_trust_score }} -->
          </h3>
          <p>{{ item.option1 }}{{ item.option2 ? `> ${item.option2}` : "" }}</p>
        </div>
      </div>
      <ul class="bottom">
        <li>
          <router-link
            to=""
            @click.native="
              likeCheck({
                wish_yn: item.wish_yn_reviwer,
                reviewer_seq: item.member_seq,
                review_seq: null,
                main_seq: item.main_menu_seq,
                type: 'reviewer',
              })
            "
          >
            <img
              v-if="item.wish_yn_reviwer == 'Y'"
              src="@/assets/images/sub/like_on.png"
              alt="" />
            <img
              v-else
              src="@/assets/images/sub/like_off.png"
              alt="" /></router-link
          ><router-link to="">{{ item.nickname }}</router-link>
        </li>
        <li>리뷰어 신뢰도 {{ rammFloat(item.re_trust_score) }}</li>
        <li>중요도 {{ rammFloat(item.news_total_score) }}</li>
        <li>
          <router-link
            to=""
            :id="`arrow_${item.seq}`"
            @click.native="openDetail()"
            class="detail_arrow"
            >∨</router-link
          >
        </li>
      </ul>
      <router-link
        to=""
        @click.native="goDetailList"
        class="move_ico"
        v-if="!totalShow && !realTimeShow && !isCategory"
        ><img src="@/assets/images/sub/list_move_ico.jpg" alt=""
      /></router-link>
    </div>
    <div
      class="box_2 detail"
      style="display: none"
      :id="`item_detail_${item.seq}`"
    >
      <!--뉴스-일반-->
      <div class="top_2">
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template v-if="parseInt(item.member_seq) === parseInt(isReviewer)">
            <!-- <router-link to="" @click.native="modifyReview"
              ><span>수정</span></router-link
            ><router-link to="" @click.native="deleteReview"
              ><span>삭제</span></router-link
            > -->
            <router-link to="" @click.native="modifyReview">수정</router-link
            >&nbsp;&nbsp;|&nbsp;&nbsp;<router-link
              to=""
              @click.native="deleteReview"
              >삭제</router-link
            >
          </template>
        </div>
        <div class="right">
          <b
            >리뷰어와 독자들의 뉴스 중요도 합산 평점&nbsp;&nbsp;{{
              rammFloat(item.news_total_score)
            }}</b
          >
          <!-- <br />리뷰어의 뉴스 중요도&nbsp;&nbsp;{{ item.main_score }} -->
        </div>
      </div>
      <!-- <div
        class="top_2"
        v-if="
          parseInt(item.option_category1) === parseInt(this.media) ||
          parseInt(item.option_category1) === parseInt(this.realTime)
        "
      >
        <div
          class="left"
          v-if="
            myInfo && parseInt(myInfo.member_seq) === parseInt(item.member_seq)
          "
        >
          <template v-if="parseInt(item.member_seq) === parseInt(isReviewer)">
            <router-link to="" @click.native="modifyReview">수정</router-link
            >&nbsp;&nbsp;|&nbsp;&nbsp;<router-link
              to=""
              @click.native="deleteReview"
              >삭제</router-link
            >
          </template>
        </div>
        <div class="right">
          <b
            >리뷰어와 독자들의 뉴스 중요도 합산 평점&nbsp;&nbsp;{{
              rammFloat(item.news_total_score)
            }}
          </b>
        </div>
      </div> -->
      <!--뉴스-특정-->
      <!--<div class="top_2">
          <div class="left"><a href="#">수정</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="#">삭제</a></div>
          <div class="right"><b>독자들의 리뷰 신뢰도&nbsp;&nbsp;4.5</b></div>
      </div>-->
      <h3>
        {{ item.title }}
      </h3>
      <div class="middle" v-html="item.content">
        {{ item.content }}
      </div>
      <div class="middle_end">
        <div class="left">{{ item.nickname }}</div>
        <div class="right">{{ day(item.reg_dt) }}</div>
      </div>

      <hr class="content_line" />
      <br />

      <div class="sect_news_link" v-if="item.news_link">
        <router-link to="" @click.native="linkClick(item.news_link)">
          <!-- <div class="link_top">{{ item.news_link }}</div> -->
          <div class="link_move">
            <div class="left">원문기사보기</div>
            <div class="right">&gt;&gt;&gt;</div>
          </div>
          <div class="link_bottom" v-if="item.meta">
            <div class="link_b_left">
              <img :src="item.meta.og_image" @error="replaceImg" alt="" />
            </div>
            <div class="link_b_right">
              <div class="news_title" v-html="item.meta.og_title">
                {{ item.meta.og_title }}
              </div>
              <div v-html="item.meta.og_description">
                {{ item.meta.og_description }}
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <div
        class="info_bt"
        @click="openInfo()"
        v-if="
          parseInt(item.option_category1) !== this.realTime &&
          parseInt(item.option_category1) !== this.media
        "
      >
        * '기사읽기'를 하신 분은 아래 평점을 매겨주세요!<router-link to=""
          ><span :id="`arrow_info_${item.seq}`">∨</span></router-link
        >
      </div>
      <div class="info_word" :id="`arrow_info_word_${item.seq}`">
        <p>
          리뷰의 원 기사를 읽은 후, 아래 '리뷰 만족도'와 '뉴스 중요도'에 평점을
          매겨주세요. 여러분의 활동에 의해 이 리뷰와 리뷰어의 신뢰도가
          결정됩니다.
        </p>
        <p>
          리뷰어의 리뷰 중 과장이나 축소, 왜곡 등등은 모두 부정직한 리뷰입니다
          리뷰의 본질을 망각한 글은 누구나 '신고'할 수 있으며 일정 수 이상에
          의해 신고된 리뷰는 자동 검색 누락이 됩니다. 악의적인 신고로 피해를
          당한 리뷰어는 당사에 항의할 수 있으며 피해자로 확인될 경우 그 리뷰는
          복원됩니다
        </p>
      </div>
      <MobAd320X250inner
        v-if="
          parseInt(this.item.option_category1) === parseInt(this.realTime) ||
          parseInt(this.item.option_category1) === parseInt(this.media)
        "
      />
      <div
        class="info_bt"
        v-if="
          parseInt(this.item.option_category1) === parseInt(this.realTime) ||
          parseInt(this.item.option_category1) === parseInt(this.media)
        "
      >
        * '리뷰' 평점을 매겨주세요.
      </div>
      <!-- <div class="score_num">
        <span>리뷰 만족도</span>
        <ul v-if="0 < parseInt(reviewScore(item.review_score))">
          <li
            v-for="(count, index) in parseInt(reviewScore(item.review_score))"
            :key="`${index}_on`"
          >
            <router-link to="" @click.native="setReviewScore(count)"
              ><img
                :src="require(`@/assets/images/sub/score_num_${count}_on.png`)"
                alt=""
            /></router-link>
          </li>
          <li
            v-for="(count, index) in 5 -
            parseInt(reviewScore(item.review_score))"
            :key="`${index}_off`"
          >
            <router-link
              to=""
              @click.native="
                setReviewScore(count + parseInt(reviewScore(item.review_score)))
              "
              ><img
                :src="
                  require(`@/assets/images/sub/score_num_${
                    count + parseInt(reviewScore(item.review_score))
                  }_off.png`)
                "
                alt=""
            /></router-link>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(count, index) in parseInt(5)" :key="index">
            <router-link to="" @click.native="setReviewScore(count)"
              ><img
                :src="require(`@/assets/images/sub/score_num_${count}_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div> -->
      <!-- <div class="score_star">
        <span>뉴스 중요도</span>
        <ul v-if="0 < parseInt(evaluationScore(item.score))">
          <li
            v-for="(count, index) in parseInt(evaluationScore(item.score))"
            :key="`${index}_on`"
          >
            <router-link
              to=""
              @click.native="setNewsScore(count, item.goods_seq)"
              ><img
                :src="require(`@/assets/images/sub/score_star_on.png`)"
                alt=""
            /></router-link>
          </li>
          <li
            v-for="(count, index) in 5 - parseInt(evaluationScore(item.score))"
            :key="`${index}_off`"
          >
            <router-link
              to=""
              @click.native="
                setNewsScore(
                  count + parseInt(evaluationScore(item.score)),
                  item.goods_seq
                )
              "
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(count, index) in parseInt(5)" :key="index">
            <router-link
              to=""
              @click.native="setNewsScore(count, item.goods_seq)"
              ><img
                :src="require(`@/assets/images/sub/score_star_off.png`)"
                alt=""
            /></router-link>
          </li>
        </ul>
      </div> -->
      <ReviewSetScore
        :id="3"
        :item="item"
        :myInfo="myInfo"
        @updateScores="updateScores"
        @needsLogin="needsLogin"
        ref="review_score"
      />
      <!---->
      <div class="rn_ms_empty_20"></div>
      <!---->
      <div class="btn_2">
        <router-link
          to=""
          class="left black"
          @click.native="
            likeCheck({
              wish_yn: item.wish_yn,
              review_seq: item.seq,
              reviewer_seq: null,
              main_seq: item.main_menu_seq,
              type: 'review',
            })
          "
          >♡ 이 리뷰 찜하기</router-link
        >
        <router-link to="" @click.native="openDeclaration()" class="right white"
          >신고하기</router-link
        >
        <router-link to="" class="right gray" @click.native="openPopSns()"
          >SNS공유하기</router-link
        >
        <ReviewSnsShare
          :item="item"
          :nowPage="pageUrl"
          :ref="`sns_share_${item.seq}`"
          :id="`sns_share_${item.seq}`"
        />
        <ReviewDeclaration
          :item="item"
          :id="`declaration_${item.seq}`"
          style="display: none"
        />
      </div>
      <div class="reply_btn" @click="openReply()">
        <router-link to=""
          >댓글 {{ item.comment_cnt }}개
          <span :id="`arrow_reply_${item.seq}`">∨</span></router-link
        >
      </div>
      <!---->
      <div class="rn_ms_empty_20"></div>
      <!---->
      <ReviewReply
        :id="`reply_view_${item.seq}`"
        :item="item"
        :replyListArray="replyListArray"
        :page="parseInt(replyPage)"
        :ref="`reply_view_${item.seq}`"
        @setSort="setSort"
        @getReplyList="getReplyList"
        class="reply_list_box"
        style="display: none"
      />
    </div>
    <!-- <ReviewNewsClick
      class="modals"
      v-if="newsClick"
      :newsCLickTime="newsCLickTime"
      :newsCount="newsCount"
      :nickname="item.nickname"
      :myInfo="myInfo"
    /> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import ReviewSnsShare from "@/components/review/state/ReviewSnsShare";
import ReviewDeclaration from "@/components/review/state/ReviewDeclaration";
import ReviewReply from "@/components/review/state/ReviewReply";
import MobAd320X250inner from "@/components/ad/MobAd320X250inner";
import ReviewSetScore from "@/components/review/state/ReviewSetScore.vue";
// import ReviewNewsClick from "@/components/review/state/ReviewNewsClick";
export default {
  data() {
    return {
      reviewTmpScore: 0,
      tmpScore: 0,
      orgReviewScore: this.item.review_score,
      orgScore: this.item.score,
      replyPage: 0,
      sort: "DESC",
      newsClick: false,
      newsCLickTime: parseInt(process.env.VUE_APP_NEW_CLICK_TIME),
      realTime: parseInt(process.env.VUE_APP_NEWS_REAL_TIME),
      media: parseInt(process.env.VUE_APP_NEWS_MEDIA),
      newsMenu: 3,
      isOpen: false,
      domain: process.env.VUE_APP_API_URL,
    };
  },
  props: {
    totalShow: {
      type: Boolean,
      default: false,
    },
    realTimeShow: {
      type: Boolean,
      default: false,
    },
    isCategory: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    nowPage: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
      default: 1,
    },
    menu: {
      type: Number,
      default: 0,
    },
    paramsQuery: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState("mypage", ["myInfo"]),
    ...mapState("review", [
      "replyListArray",
      "newsContents",
      "msg",
      "result",
      "limit",
      "newsCount",
    ]),
    pageUrl() {
      // console.log(this.nowPage);
      return this.nowPage.replace(/(&?seq=[a-z|A-Z|0-9])/gi, "");
    },
    isReviewer() {
      if (
        this.$store.state.mypage.myInfo.is_reviewer === "Y" &&
        this.$store.state.mypage.channel.reviewer
      ) {
        return this.$store.state.mypage.myInfo.member_seq;
      }
      return 0;
    },
  },
  methods: {
    day(str) {
      return this.$moment(str).format("YYYY.MM.DD");
    },
    replaceImg(e) {
      e.target.src = require("@/assets/images/newsNoimg.png");
    },
    needsLogin() {
      if (!this.$store.state.member.isLogin) {
        this.$confirm("로그인이 필요합니다.")
          .then(() => {
            this.$store.dispatch("common/setReferer", {
              path: this.isOpen
                ? this.nowPage.replace(this.domain, "") +
                  "&seq=" +
                  this.item.seq
                : this.$route.path,
            });
            this.$router.push("/member/login");
            return false;
          })
          .catch(() => {
            return false;
          });
      } else {
        return true;
      }
    },
    async likeCheck(params) {
      if (!this.needsLogin()) return false;

      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }

      await this.$store.dispatch("wish/updateWish", params);
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("review/updateWish", {
          seq: params.review_seq,
          reviewer: params.reviewer_seq,
          wishYn: params.wish_yn,
          type: params.type,
        });
      }
      // if (params.type === "review") {
      //   const url = `/wish/review?menu=${this.newsMenu}`;
      //   this.$router.push(url);
      // }
    },
    async getReplyList(reset = false) {
      if (reset) {
        this.replyPage = 0;
      }
      await this.$store.dispatch("review/getReplyList", {
        seq: this.item.seq,
        page: this.replyPage,
        sort: this.sort,
        reset,
      });
      if (!this.result) {
        this.$toast.default(this.msg);
      }
      this.replyPage++;
    },
    async openDetail() {
      const detail = document.querySelector(`#item_detail_${this.item.seq}`);
      const arrow = document.querySelector(`#arrow_${this.item.seq}`);
      const box2 = document.querySelectorAll(".box_2.detail");
      const video = document.querySelectorAll(
        `#item_detail_${this.item.seq} .ql-video`
      );
      if ([...video].length > 0) {
        const height = document.querySelector("body").offsetWidth * 0.5625;
        [...video].map((v) => {
          v.style.width = "100%";
          v.style.height = `${height - 10}px`;
          const src = `${v.src}&enablejsapi=1`;
          const index = v.src
            .split("&")
            .findIndex((s) => s === "enablejsapi=1");
          if (index === -1) {
            v.src = src;
          }
        });
      }
      this.resetDetail(); //초기화
      [...box2].map((box) => {
        if (box !== detail) box.style.display = "none";
      });
      if (detail.style.display === "none") {
        detail.style.display = "block";
        arrow.textContent = "∧";
        this.$store.dispatch("review/setViewHit", {
          review_seq: this.item.seq,
        });
        if (this.item.news_link) {
          await this.$store.dispatch("review/getMetaData", {
            url: this.item.news_link,
          });
          if (this.result && Object.keys(this.newsContents).length > 0) {
            this.$store.dispatch("review/updateNewsMeta", {
              seq: this.item.seq,
              newsContents: this.newsContents,
            });
          }
        }
        this.isOpen = true;
        this.$emit("setSeq", this.item.seq);
      } else {
        if ([...video].length > 0) {
          [...video].map((v) => {
            v.contentWindow.postMessage(
              '{"event":"command","func":"' + "stopVideo" + '","args":""}',
              "*"
            );
          });
        }
        detail.style.display = "none";
        arrow.textContent = "∨";
        this.isOpen = false;
        this.$emit("setSeq", "0");
      }
    },
    resetDetail() {
      // 상세 페이지가 열릴 떄 마다 초기화
      this.item.review_score = this.orgReviewScore;
      this.item.score = this.orgScore;
      this.reviewTmpScore = 0;
      this.tmpScore = 0;
      const snsClass = document.querySelectorAll(".sns_box");
      const declPop = document.querySelectorAll(".declaration_pop");
      const replyList = document.querySelectorAll(".reply_list_box");
      const arrowReply = document.querySelector(
        `#arrow_reply_${this.item.seq}`
      );
      const video = document.querySelectorAll(".ql-video");
      const detailArrow = document.querySelectorAll(".detail_arrow");
      const infoWord = document.querySelectorAll(".info_word");
      const arrowInfo = document.querySelector(`#arrow_info_${this.item.seq}`);

      if (infoWord && arrowInfo) {
        [...infoWord].map((word) => {
          word.style.display = "none";
        });
        arrowInfo.textContent = "∨";
      }

      [...snsClass].map((sns) => {
        sns.style.display = "none";
      });
      [...declPop].map((decl) => {
        decl.style.display = "none";
      });
      [...replyList].map((reply) => {
        reply.style.display = "none";
      });
      [...detailArrow].map((deatil) => (deatil.textContent = "∨"));
      arrowReply.textContent = "∨";
      if ([...video].length > 0) {
        [...video].map((v) => {
          v.contentWindow.postMessage(
            '{"event":"command","func":"' + "stopVideo" + '","args":""}',
            "*"
          );
        });
      }
    },
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    openInfo() {
      const arrowInfo = document.querySelector(`#arrow_info_${this.item.seq}`);
      const arrowInfoWord = document.querySelector(
        `#arrow_info_word_${this.item.seq}`
      );

      const infoWord = document.querySelectorAll(".info_word");
      [...infoWord].map((word) => {
        if (word !== arrowInfoWord) word.style.display = "none";
      });
      if (arrowInfoWord.style.display === "none") {
        arrowInfoWord.style.display = "block";
        arrowInfo.textContent = "∧";
      } else {
        arrowInfoWord.style.display = "none";
        arrowInfo.textContent = "∨";
      }
    },
    openDeclaration() {
      if (!this.needsLogin()) return false;

      const allPop = document.querySelectorAll(".declaration_pop");
      const pop = document.querySelector(`#declaration_${this.item.seq}`);

      [...allPop].map((ap) => {
        if (ap !== pop) {
          ap.style.display = "none";
        }
      });
      if (pop.style.display === "none") {
        pop.style.display = "block";
      } else {
        pop.style.display = "none";
      }
    },
    reviewScore(score) {
      if (score) {
        return score;
      }
      return 0;
    },
    evaluationScore(score) {
      if (score) {
        return score;
      }
      return 0;
    },
    openPopSns() {
      this.$refs[`sns_share_${this.item.seq}`].openPopSns(this.item.seq);
    },
    openReply() {
      const replyEl = document.querySelector(`#reply_view_${this.item.seq}`);
      const arrowReply = document.querySelector(
        `#arrow_reply_${this.item.seq}`
      );
      const reRePlyList = document.querySelectorAll(".re_reply_box");
      [...reRePlyList].map((list) => {
        list.style.display = "none";
      });
      [...document.querySelectorAll(".replyArrow")].map(
        (re) => (re.textContent = "∨")
      );
      const replyList = document.querySelectorAll(".reply_list_box");
      [...replyList].map((reply) => {
        if (reply !== replyEl) {
          reply.style.display = "none";
        }
      });
      if (replyEl.style.display === "none") {
        replyEl.style.display = "block";
        arrowReply.textContent = "∧";

        // let news = sessionStorage.getItem("news");
        //   news = news.split(",");
        //   let index = news.findIndex(
        //     (n) => parseInt(n) === parseInt(this.item.seq)
        //   );
        //   if (index !== -1 && this.$store.state.member.isLogin) {
        //     this.$refs[`reply_view_${this.item.seq}`].isCheckAuth = true;
        //   }

        if (this.$store.state.member.isLogin) {
          this.$refs[`reply_view_${this.item.seq}`].isCheckAuth = true;
        }
        this.getReplyList(true);
      } else {
        replyEl.style.display = "none";
        arrowReply.textContent = "∨";
      }
    },
    setSort(sort) {
      this.sort = sort;
      this.getReplyList(true);
    },
    setReviewScore(score) {
      if (!this.needsLogin()) return false;
      if (
        parseInt(this.myInfo.member_seq) === parseInt(this.item.member_seq) &&
        this.$store.state.mypage.channel.reviewer
      ) {
        this.$toast.default(
          "본인이 작성한 리뷰에는 평점을 부여하실 수 없습니다."
        );
        return false;
      }
      if (
        parseInt(this.item.option_category1) !== parseInt(this.realTime) &&
        parseInt(this.item.option_category1) !== parseInt(this.media)
      ) {
        let news = localStorage.getItem("news");
        if (!news) {
          this.$toast.default(
            "리뷰 만족도를 부여하기 위해 '기사읽기' 해주세요."
          );
          return false;
        }
        news = news.split(",");
        let index = news.findIndex(
          (n) => parseInt(n) === parseInt(this.item.seq)
        );
        if (index === -1) {
          this.$toast.default(
            "리뷰 만족도를 부여하기 위해 '기사읽기' 해주세요."
          );
          return false;
        }
      }

      if (parseInt(this.reviewTmpScore) > 0 && parseInt(this.tmpScore) <= 0) {
        this.$toast.default(
          "이미 리뷰 만족도를 부여하셨습니다. 뉴스 중요도를 부여해주세요.",
          { duration: 3000 }
        );
        return false;
      }

      if (parseInt(this.item.review_score) > 0) {
        this.$toast.default("이미 리뷰 만족도를 평가 하셨습니다.");
        return false;
      } else {
        this.$confirm(`리뷰 만족도 ${score}점을 부여하시겠습니까?`)
          .then(async () => {
            this.reviewTmpScore = score;
            this.item.review_score = score;
            if (this.tmpScore <= 0) {
              this.$toast.default("뉴스 중요도를 부여해주세요.", {
                duration: 3000,
              });
              return false;
            } else {
              await this.setMakeReviewNewsScore();
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    setNewsScore(score, goodsSeq) {
      if (!this.needsLogin()) return false;
      if (
        parseInt(this.myInfo.member_seq) === parseInt(this.item.member_seq) &&
        this.$store.state.mypage.channel.reviewer
      ) {
        this.$toast.default(
          "본인이 작성한 리뷰에는 평점을 부여하실 수 없습니다."
        );
        return false;
      }
      if (
        parseInt(this.item.option_category1) !== parseInt(this.realTime) &&
        parseInt(this.item.option_category1) !== parseInt(this.media)
      ) {
        let news = localStorage.getItem("news");
        if (!news) {
          this.$toast.default(
            "뉴스 중요도를 부여하기 위해 '기사읽기' 해주세요."
          );
          return false;
        }
        news = news.split(",");
        let index = news.findIndex(
          (n) => parseInt(n) === parseInt(this.item.seq)
        );
        if (index === -1) {
          this.$toast.default(
            "뉴스 중요도를 부여하기 위해 '기사읽기' 해주세요."
          );
          return false;
        }
      }

      if (parseInt(this.tmpScore) > 0 && parseInt(this.reviewTmpScore) <= 0) {
        this.$toast.default(
          "이미 뉴스 중요도를 부여하셨습니다. 리뷰 만족도를 부여해주세요.",
          { duration: 3000 }
        );
        return false;
      }

      if (parseInt(this.item.score) > 0) {
        this.$toast.default("이미 뉴스 중요도 평점을 부여하셨습니다.");
        return false;
      } else {
        this.$confirm(`뉴스 중요도 ${score}점을 부여하시겠습니까?`)
          .then(async () => {
            this.tmpScore = score;
            this.item.score = score;
            if (this.reviewTmpScore <= 0) {
              this.$toast.default("리뷰 만족도를 부여해주세요.", {
                duration: 3000,
              });
              return false;
            } else {
              await this.setMakeReviewNewsScore();
            }
          })
          .catch(() => {
            return false;
          });
      }
    },
    updateScores(reviewScore, score) {
      this.orgReviewScore = reviewScore;
      this.orgScore = score;
    },
    async setMakeReviewNewsScore() {
      await this.$store.dispatch("review/setMakeReviewNewsScore", {
        review_score: this.item.review_score,
        score: this.item.score,
        review_seq: this.item.seq,
        option1: this.item.option_category1,
      });
      if (this.result) {
        this.orgReviewScore = this.item.review_score;
        this.orgScore = this.item.score;
      }
      this.$toast.default(this.msg);
    },
    deleteReview() {
      this.$confirm("해당 리뷰를 삭제하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("review/deleteReview", {
            seq: this.item.seq,
          });
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    linkClick(url) {
      this.$store.dispatch("review/setNewsHit", { review_seq: this.item.seq });
      window.open(
        `${process.env.VUE_APP_API_URL}/ramm_api/advertisement?review_seq=${this.item.seq}`,
        "리뷰하고돈벌자"
      );
    },
    // linkClick(url) {
    //   this.newsClick = true;
    //   let clickList = [];
    //   document.querySelector("body").style.overflow = "hidden";
    //   this.$store.dispatch("common/setFooter", {
    //     viewFooter: false,
    //   });

    //   const timer = setInterval(() => {
    //     this.newsCLickTime--;
    //     if (this.newsCLickTime < 1) {
    //       this.$store.dispatch("common/setFooter", {
    //         viewFooter: true,
    //       });
    //       document.querySelector("body").style.overflow = "";
    //       this.newsClick = false;
    //       this.newsCLickTime = parseInt(process.env.VUE_APP_NEW_CLICK_TIME);
    //       const news = sessionStorage.getItem("news");
    //       if (news) {
    //         sessionStorage.removeItem("news");
    //         clickList = news.split(",");
    //       }
    //       clickList.push(this.item.seq);
    //       clickList = [...new Set(clickList)];
    //       sessionStorage.setItem("news", clickList);
    //       this.clearTimer(timer, url);
    //     }
    //   }, 1000);
    // },
    // clearTimer(timer, url) {
    //   let winOpen = window.open("about:blank", "_bank");
    //   clearInterval(timer);
    //   winOpen.location = url;
    // },
    goDetailList() {
      this.$emit("goDetailList", this.item.option_category2);
    },
    modifyReview() {
      const query = `?${this.paramsQuery}&seq=${this.item.seq}&mode=modify`;
      if (parseInt(this.item.option_category1) === parseInt(this.realTime)) {
        this.$router.push(
          `/review/review_news_write/${this.item.option_category1}${query}`
        );
      } else {
        this.$router.push(
          `/review/review_news_write/${this.item.option_category1}/${
            this.item.option_category2 === null ? 0 : this.item.option_category2
          }${query}`
        );
      }
    },
  },
  components: {
    ReviewSnsShare,
    ReviewDeclaration,
    ReviewReply,
    // ReviewNewsClick,
    MobAd320X250inner,
    ReviewSetScore,
  },
};
</script>

<style lang="scss" scoped>
.news_title {
  font-weight: 600;
}

.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: #fff;
  height: 100%;
  overflow: scroll;
}
.menu_title {
  padding: 10px 0;
  font-weight: 600;
  // border-bottom: 1px solid #ccc;
  margin: 0 0 5px 0px;
  background: #fff;
}
.rn_ms_box_9 {
  .location {
    margin-bottom: 5px !important;
  }
}
.top_1,
.top_2 {
  a {
    span:first-child {
      margin-right: 5px;
    }
  }
}
</style>
