<template>
  <div style="margin-bottom: 20px; text-align: center">
    <!-- <VueScriptComponent
      script='
    <script type="text/javascript">
      var zone = "10881341";
      var adType = "banner";
      var bannerType = "bottom";
      var width = "300";
      var height = "250";
      var id = "mobwith_" + zone;
      var pb = "";
      var position = "";

      new MobWithAd({
        id: id,
        zone: zone,
        adType: adType,
        bannerType: bannerType,
        width: width,
        height: height,
        pb: pb,
        position: position,
      });
      document.querySelector("#mobwith_10881341").style.margin = "0 auto";
    </script>
    '
    /> -->
    <iframe
      style="margin: 0 auto"
      src="https://www.mobwithad.com/banner/bottom?zone=10881341&w=300&h=250"
      width="300"
      height="250"
      frameborder="0"
      allowtransparency="true"
      scrolling="no"
    ></iframe>
  </div>
</template>

<script>
import VueScriptComponent from "vue-script-component";
export default {
  // components: {
  //   VueScriptComponent,
  // },
};
</script>

<style></style>
